import {
	ActionCreator,
	createFeature,
	createReducer,
	on,
	ReducerTypes,
} from '@ngrx/store';
import type { FilterEntityType } from '../models';
import { clearPageEntity, setPageEntity } from './filter.actions';
import * as CMMSActions from '@app/pages/cmms/state/cmms.actions';
import { mapCmmsActionToPageEntity, mapTableTypeToPageEntity } from '../utils';
import { ViewsActions } from '@app/pages/views/state/views.actions';

interface FilterState {
	pageEntity: FilterEntityType | undefined;
}

const initialState: FilterState = {
	pageEntity: undefined,
};

const cmmsActionsReducer: ReducerTypes<FilterState, ActionCreator[]>[] = [
	on(
		CMMSActions.workOrdersOpened,
		CMMSActions.incidentsOpened,
		CMMSActions.requestOpened,
		CMMSActions.requestOpened,
		CMMSActions.fileOpened,
		CMMSActions.assetsOpened,
		CMMSActions.messagesOpened,
		CMMSActions.contactsOpened,
		(state, action) => ({
			...state,
			pageEntity: mapCmmsActionToPageEntity(action),
		})
	),
];

const viewsActionsReducer: ReducerTypes<FilterState, ActionCreator[]>[] = [
	on(
		ViewsActions.createViewSelectEntityType,
		ViewsActions.updateViewChangeEntityType,
		(state, { entity }) => ({
			...state,
			pageEntity: mapTableTypeToPageEntity(entity?.type),
		})
	),
	on(ViewsActions.loadViewSuccess, ViewsActions.updateViewSuccess, (state, { view }) => ({
		...state,
		pageEntity: mapTableTypeToPageEntity(view?.uiTable?.tableType),
	})),
	on(
		ViewsActions.createViewCancelEntityTypeSelection,
		ViewsActions.cancelCreateView,
		state => ({
			...state,
			pageEntity: undefined,
		})
	),
];

const filterReducer = createReducer(
	initialState,
	on(setPageEntity, (state: FilterState, { entity }) => ({
		...state,
		pageEntity: entity,
	})),
	on(clearPageEntity, (state: FilterState) => ({
		...state,
		pageEntity: undefined,
	})),
	// External actions
	...cmmsActionsReducer,
	...viewsActionsReducer
);

export const filterFeature = createFeature({
	name: 'filter',
	reducer: filterReducer,
});

export const { name, reducer, selectPageEntity } = filterFeature;
