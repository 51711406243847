import { CmmsPages } from '@app/pages/cmms/state/cmms.reducer';
import type { FilterEntityType } from './models';
import { TableType } from '@app/pages/cmms/types';
import type { DTTypeFilter } from '@app/pages/digital-twin/pages/building-viewer/services/building-viewer-markers.service';
import {
	assetsOpened,
	contactsOpened,
	fileOpened,
	incidentsOpened,
	messagesOpened,
	requestOpened,
	workOrdersOpened,
} from '@app/pages/cmms/state/cmms.actions';
import { Action } from '@ngrx/store';

export function mapCmmsPageToPageEntity(page: CmmsPages): FilterEntityType | undefined {
	switch (page) {
		case CmmsPages.wo:
			return 'Work Order';
		case CmmsPages.requests:
			return 'Request';
		case CmmsPages.incidents:
			return 'Incident';
		case CmmsPages.files:
			return 'File';
		case CmmsPages.assets:
			return 'Asset';
		case CmmsPages.messages:
			return 'Message';
		case CmmsPages.contacts:
			return 'Contact';
		default:
			return undefined;
	}
}

export function mapTableTypeToPageEntity(
	tableType: TableType
): FilterEntityType | undefined {
	switch (tableType) {
		case TableType.WORK_ORDER:
			return 'Work Order';
		case TableType.INCIDENTS:
			return 'Incident';
		case TableType.REQUESTS:
			return 'Request';
		case TableType.FILES:
			return 'File';
		case TableType.ASSETS:
			return 'Asset';
		default:
			return undefined;
	}
}

export function mapTwinFilterTypeToPageEntity(
	filterType: DTTypeFilter
): FilterEntityType | undefined {
	switch (filterType) {
		case 'WorkOrders':
			return 'Work Order';
		case 'Incidents':
			return 'Incident';
		case 'Assets':
			return 'Asset';
		default:
			return undefined;
	}
}

export function mapPageEntityToTwinFilterType(
	pageEntity: FilterEntityType
): DTTypeFilter | undefined {
	switch (pageEntity) {
		case 'Work Order':
			return 'WorkOrders';
		case 'Asset':
			return 'Assets';
		case 'Incident':
			return 'Incidents';
		default:
			return undefined;
	}
}

export function mapCmmsActionToPageEntity(action: Action): FilterEntityType | undefined {
	switch (action.type) {
		case workOrdersOpened.type:
			return 'Work Order';
		case incidentsOpened.type:
			return 'Incident';
		case requestOpened.type:
			return 'Request';
		case fileOpened.type:
			return 'File';
		case assetsOpened.type:
			return 'Asset';
		case messagesOpened.type:
			return 'Message';
		case contactsOpened.type:
			return 'Contact';
	}
	return undefined;
}
